import React from 'react';
import styled, { css } from 'styled-components';
import { getSrc } from "gatsby-plugin-image";
import { graphql } from 'gatsby';

import Facebook from '../blog/components/icons/facebook';
import Footer from '../blog/components/Footer';
import Globe from '../blog/components/icons/globe';
import PostCard from '../blog/components/PostCard';
import SiteNav from '../blog/components/Header/SiteNav';
import Twitter from '../blog/components/icons/twitter';
import Wrapper from '../blog/components/Wrapper/Wrapper';

import IndexLayout from '../layouts';
import {
  AuthorProfileImage,
  inner,
  outer,
  PostFeed,
  SiteHeader,
  SiteHeaderContent,
  SiteTitle,
  SiteMain,
  SocialLink,
} from '../styles/shared';

import MetaData from '../containers/MetaData';
import { about } from '../website-config';

const HiddenMobile = css`
  @media (max-width: 500px) {
    display: none;
  }
`;

const AuthorMeta = styled.div`
  z-index: 10;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 10px 0;
  font-family: Georgia, serif;
  font-style: italic;
`;

const AuthorBio = styled.h2`
  z-index: 10;
  flex-shrink: 0;
  margin: 5px 0 10px 0;
  max-width: 600px;
  font-size: 2rem;
  line-height: 1.3em;
  font-weight: 300;
  letter-spacing: 0.5px;
  opacity: 0.8;
`;

const Bull = styled.span`
  display: inline-block;
  margin: 0 12px;
  opacity: 0.5;
`;

const AuthorProfileBioImage = css`
  z-index: 10;
  flex-shrink: 0;
  margin: 0 0 20px 0;
  width: 100px;
  height: 100px;
  box-shadow: rgba(255, 255, 255, 0.1) 0 0 0 6px;
`;

const Author = props => {
  const author = props.data.authorYaml;
  const useImage = author === 'David Nowak';

  const edges = props.data.allMarkdownRemark.edges.filter(
    edge => {
      const isDraft = (edge.node.frontmatter.draft !== true ||
        process.env.NODE_ENV === 'development');
      return isDraft && edge.node.frontmatter?.author.name === author.name;
    }
  );
  const totalCount = edges.length;

  return (
    <IndexLayout>
      <MetaData 
        description={author.description || author.bio.slice(0, 145)}
        height={useImage ? about.imgHeight : null}
        image={useImage ? about.imageUrl.slice(23) : null}
        imageAlt={useImage ? about.imgAlt : null}
        robots="index, follow"
        title={`${author.name} - Content Author`}
        type="profile"
        url={props.pageContext.slug}
        width={useImage ? about.imgWidth : null}
      />
      <Wrapper>
        <SiteHeader
          className="no-cover"
          css={outer}
          bgImg={author?.profile ? getSrc(author?.profile_image.childImageSharp) : null}
        >
          <div css={inner}>
            <SiteNav isHome={false} />
            <SiteHeaderContent>
              <AuthorProfileImage
                css={AuthorProfileBioImage}
                src={getSrc(author.avatar.childImageSharp)}
                alt={author.name}
              />
              <SiteTitle>{author.name}</SiteTitle>
              {author.bio && <AuthorBio>{author.bio}</AuthorBio>}
              <AuthorMeta>
                {author.location && (
                  <div css={HiddenMobile}>
                    {author.location} <Bull>&bull;</Bull>
                  </div>
                )}
                <div css={HiddenMobile}>
                  {totalCount > 1 && `${totalCount} posts`}
                  {totalCount === 1 && '1 post'}
                  {totalCount === 0 && 'No posts'} <Bull>•</Bull>
                </div>
                {author.website && (
                  <div>
                    <a
                      className="social-link-wb"
                      css={SocialLink}
                      href={author.website}
                      title="Website"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Globe size={1.08} />
                    </a>
                  </div>
                )}
                {author.twitter && (
                  <a
                    className="social-link-tw"
                    css={SocialLink}
                    href={`https://twitter.com/${author.twitter}`}
                    title="Twitter"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Twitter size={1.3} />
                  </a>
                )}
                {author.facebook && (
                  <a
                    className="social-link-fb"
                    css={SocialLink}
                    href={`https://www.facebook.com/${author.facebook}`}
                    title="Facebook"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Facebook size={1.3} />
                  </a>
                )}
              </AuthorMeta>
            </SiteHeaderContent>
          </div>
        </SiteHeader>
        <SiteMain useOuter>
          <div css={inner}>
            <PostFeed>
              {edges.map(({ node }) => {
                return <PostCard key={node.fields.slug} post={node} />;
              })}
            </PostFeed>
          </div>
        </SiteMain>
        <Footer />
      </Wrapper>
    </IndexLayout>
  );
};

export default Author;

export const pageQuery = graphql`
  query($author: String) {
    authorYaml(name: { eq: $author }) {
      name
      website
      twitter
      bio
      facebook
      location
      profile_image {
        childImageSharp {
          gatsbyImageData(width: 3720)
        }
      }
      avatar {
        childImageSharp {
          gatsbyImageData(width: 200)
        }
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { draft: { ne: true } } },
      sort: { fields: [frontmatter___date], order: DESC },
      limit: 2000,
    ) {
      edges {
        node {
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            date
            userDate: date(formatString: "MMMM DD, YYYY")
            draft
            image {
              childImageSharp {
                gatsbyImageData(width: 3720)
              }
            }
            author {
              bio
              description
              name
              avatar {
                children {
                  ... on ImageSharp {
                    gatsbyImageData(quality: 90)
                  }
                }
              }
            }
          }
          fields {
            layout
            slug
          }
        }
      }
    }
  }
`;
